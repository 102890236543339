import './Row.scss';

import cx from 'classnames';
import React from 'react';

const Row = ({
  children,
  className,
  xxAlign,
  xxJustify,
  xsAlign,
  xsJustify,
  smAlign,
  smJustify,
  mdAlign,
  mdJustify,
  lgAlign,
  lgJustify,
  xlAlign,
  xlJustify,
}) => {
  const rootCx = cx(
    'row',
    {
      [`row--xx-${xxAlign}`]: xxAlign, // top, middle, bottom
      [`row--xx-${xxJustify}`]: xxJustify, // start, center, end, around, between
      [`row--xs-${xsAlign}`]: xsAlign,
      [`row--xs-${xsJustify}`]: xsJustify,
      [`row--sm-${smAlign}`]: smAlign,
      [`row--sm-${smJustify}`]: smJustify,
      [`row--md-${mdAlign}`]: mdAlign,
      [`row--md-${mdJustify}`]: mdJustify,
      [`row--lg-${lgAlign}`]: lgAlign,
      [`row--lg-${lgJustify}`]: lgJustify,
      [`row--xl-${xlAlign}`]: xlAlign,
      [`row--xl-${xlJustify}`]: xlJustify,
    },
    className
  );
  return <div className={rootCx}>{children}</div>;
};

export default Row;
