import './Text.scss';

import cx from 'classnames';
import React from 'react';

const Text = ({ children, className, tag }) => {
  const rootCx = cx('text', className);
  const Tag = tag || 'div';

  return <Tag className={rootCx}>{children}</Tag>;
};

export default Text;
