import { ScrollTrigger as GSAPScrollTrigger, gsap } from 'gsap/all';
import React, { useEffect, useRef } from 'react';

import { fromMd, toMd } from '../../styles/variables/_media-queries.scss';
import deepMerge from '../../utils/deepMerge';

const ScrollTrigger = ({ children, allSet = {}, desktopSet = {}, mobileSet = {} }) => {
  // Render nothing when empty
  if (!children) {
    return null;
  }

  // Consts
  const wrapperElem = useRef(null);

  // Handling scroll trigger setup
  useEffect(() => {
    const fadeInSet = {
      element: wrapperElem.current,
      from: {
        opacity: 0,
        y: '+=50',
      },
      to: {
        duration: 0.9,
        ease: 'slow',
        opacity: 1,
        scrollTrigger: {
          start: '0 85%',
          trigger: wrapperElem.current,
        },
        y: 0,
      },
    };

    GSAPScrollTrigger.matchMedia({
      // desktop
      [fromMd]: () => {
        gsap.fromTo(...Object.values(deepMerge(deepMerge(fadeInSet, allSet), desktopSet)));
      },
      // mobile
      [toMd]: () => {
        gsap.fromTo(...Object.values(deepMerge(deepMerge(fadeInSet, allSet), mobileSet)));
      },
    });
  }, [wrapperElem]);

  // Render default
  return (
    <div className="scroll-trigger" ref={wrapperElem}>
      {children}
    </div>
  );
};

export default ScrollTrigger;
