import './Col.scss';

import cx from 'classnames';
import React from 'react';

const Col = ({
  children,
  className,
  xx,
  xxOffset,
  xxOrder,
  xs,
  xsOffset,
  xsOrder,
  sm,
  smOffset,
  smOrder,
  md,
  mdOffset,
  mdOrder,
  lg,
  lgOffset,
  lgOrder,
  xl,
  xlOffset,
  xlOrder,
}) => {
  const rootCx = cx(
    'col',
    {
      [`col--xx-${xx}`]: xx, // 1, ..., 12
      [`col--xx-offset-${xxOffset}`]: xxOffset, // 1, ..., 11, off
      [`col--xx-${xxOrder}`]: xxOrder, // first, last
      [`col--xs-${xs}`]: xs,
      [`col--xs-offset-${xsOffset}`]: xsOffset,
      [`col--xs-${xsOrder}`]: xsOrder,
      [`col--sm-${sm}`]: sm,
      [`col--sm-offset-${smOffset}`]: smOffset,
      [`col--sm-${smOrder}`]: smOrder,
      [`col--md-${md}`]: md,
      [`col--md-offset-${mdOffset}`]: mdOffset,
      [`col--md-${mdOrder}`]: mdOrder,
      [`col--lg-${lg}`]: lg,
      [`col--lg-offset-${lgOffset}`]: lgOffset,
      [`col--lg-${lgOrder}`]: lgOrder,
      [`col--xl-${xl}`]: xl,
      [`col--xl-offset-${xlOffset}`]: xlOffset,
      [`col--xl-${xlOrder}`]: xlOrder,
    },
    className
  );

  return <div className={rootCx}>{children}</div>;
};

export default Col;
