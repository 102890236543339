import './Section.scss';

import cx from 'classnames';
import React from 'react';

const Section = ({ children, className, isFullHeight, isNoSpaced, isWane }, ref) => {
  const rootCx = cx(
    'section',
    {
      'section--full-height': isFullHeight,
      'section--no-spaced': isNoSpaced,
      'section--wane': isWane,
    },
    className
  );

  return (
    <section className={rootCx} ref={ref}>
      <div className="section__inner">{children}</div>
    </section>
  );
};

export default React.forwardRef(Section);
